import DateInput, { Props as DateInputProps } from "components/DateInput";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useMemo, useState } from "react";

interface Props extends DateInputProps {
  minAge: number;
  maxAge?: number;
}

const MAX_AGE = 120;

const BirthdayInput = ({
  label,
  minAge,
  maxAge = MAX_AGE,
  onChange,
  value,
  ...dateInputProps
}: Props) => {
  const [showRangeError, setShowRangeError] = useState(false);

  const handleChange = useCallback(
    (val: Dayjs) => {
      setShowRangeError(false);

      const selectedAge = dayjs.utc().diff(val, "year", true);
      const isValidAge = selectedAge >= (minAge as number) && selectedAge <= (maxAge as number);

      onChange(val);

      if (!isValidAge) {
        return setShowRangeError(true);
      }
    },
    [onChange, maxAge, minAge]
  );

  const minYear = useMemo(() => {
    return dayjs.utc().year() - maxAge;
  }, [maxAge]);

  const maxYear = useMemo(() => {
    return dayjs.utc().year() - minAge;
  }, [minAge]);

  return (
    <div className="BirthdayInput">
      <DateInput
        label={`${label} (must be ${minAge}+)`}
        minYear={minYear}
        maxYear={maxYear}
        onChange={handleChange}
        value={value}
        {...dateInputProps}
      />
      {showRangeError && (
        <p className="range-error">
          Must be between {minAge} and {maxAge} years old.
        </p>
      )}
    </div>
  );
};

export default BirthdayInput;
